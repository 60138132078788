import React from "react";
import Image from "../Image/Image";
import style from "./index.module.css";

function Project({
  project: {
    image_link,
    title,
    live_link: live_url,
    github_url,
    product_url,
    blog_url,
    description,
    technologies,
    roles,
    company,
    tag,
  },
  index,
}) {
  const checkTag = (tag_) => {
    if (["project"].includes(tag_)) return true;
    return false;
  };

  const toTwoDigits = (number) => {
    number = number.toString();
    return number.length === 1 ? "0" + number : number;
  };

  return (
    <div
      className={style.project}
      data-index={toTwoDigits(index + 1)}
      data-aos="zoom-in"
    >
      <section className={style.top}>
        <p>{title}</p>
        <div className={style.icons}>
          {tag?.includes("github") && (
            <a href={github_url} target="_blank">
              <svg
                width="27"
                height="26"
                viewBox="0 0 27 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_16_17)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.9395 0C6.75695 0 0.939453 5.8175 0.939453 13C0.939453 18.7525 4.6607 23.6113 9.8282 25.3338C10.4782 25.4475 10.722 25.0575 10.722 24.7163C10.722 24.4075 10.7057 23.3838 10.7057 22.295C7.43945 22.8963 6.59445 21.4988 6.33445 20.7675C6.1882 20.3938 5.55445 19.24 5.00195 18.9313C4.54695 18.6875 3.89695 18.0863 4.9857 18.07C6.00945 18.0538 6.7407 19.0125 6.98445 19.4025C8.15445 21.3688 10.0232 20.8162 10.7707 20.475C10.8845 19.63 11.2257 19.0613 11.5995 18.7362C8.70695 18.4113 5.68445 17.29 5.68445 12.3175C5.68445 10.9038 6.1882 9.73375 7.01695 8.82375C6.88695 8.49875 6.43195 7.16625 7.14695 5.37875C7.14695 5.37875 8.2357 5.0375 10.722 6.71125C11.762 6.41875 12.867 6.2725 13.972 6.2725C15.077 6.2725 16.182 6.41875 17.222 6.71125C19.7082 5.02125 20.797 5.37875 20.797 5.37875C21.512 7.16625 21.057 8.49875 20.927 8.82375C21.7557 9.73375 22.2595 10.8875 22.2595 12.3175C22.2595 17.3062 19.2207 18.4113 16.3282 18.7362C16.7995 19.1425 17.2057 19.9225 17.2057 21.1413C17.2057 22.88 17.1895 24.2775 17.1895 24.7163C17.1895 25.0575 17.4332 25.4638 18.0832 25.3338C20.6639 24.4625 22.9064 22.8038 24.4951 20.5913C26.0838 18.3788 26.9387 15.7238 26.9395 13C26.9395 5.8175 21.122 0 13.9395 0Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_16_17">
                    <rect
                      width="26"
                      height="26"
                      fill="white"
                      transform="translate(0.939453)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
          )}

          {tag?.includes("web") && (
            <a
              style={{ cursor: "pointer", color: "white" }}
              href={live_url}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                />
              </svg>
            </a>
          )}

          {tag?.includes("product") && (
            <a
              style={{ cursor: "pointer", color: "white" }}
              href={product_url}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
              </svg>
            </a>
          )}

          {tag?.includes("blog") && (
            <a
              style={{ cursor: "pointer", color: "white" }}
              href={blog_url}
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </a>
          )}
        </div>
      </section>

      <a className={style.image}>
        <Image src={image_link} alt={title} />
      </a>

      <section className={style.description}>
        <p>{description}</p>
        <div className={style.list_area}>
          {console.log(technologies)}
          {(technologies.length > 1) && (
            <div className={style.grid}>
              <p className={style.label}>
                Technolog{technologies.length <= 1 ? "y" : "ies"}:
              </p>
              <div className={style.list}>
                {technologies.map((technology) => (
                  <span key={technology}>{technology}</span>
                ))}
              </div>
            </div>
          )}

          {roles && (
            <div className={style.grid}>
              <span className={style.label}>Role{roles.length <= 1 ? "" : "s"}:</span>
              <div className={style.list}>
                {roles.map((role) => (
                  <span key={role}>{role}</span>
                ))}
              </div>
            </div>
          )}

          {company && (
            <div>
              <span className={style.tag}>{company}</span>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default Project;
