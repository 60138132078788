import React from "react";
import Image from "../Image/Image";
import moment from "moment";
import { motion } from "framer-motion";
import style from "./index.module.css";

function SupportItem({
  item: {
    name,
    image,
    progress,
    supporters,
    linkIcon,
    twitterIcon,
    status,
    underProject,
    items,
  },
  index,
}) {
  return (
    <motion.div
      className={style.supportItem}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <section className={style.top}>
        <motion.div 
          className={style.mainImage}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Image src={image} alt={name} />
        </motion.div>
        <div className={style.titleArea}>
          <h2>{name}</h2>
          <div className={style.icons}>
            {linkIcon && (
              <motion.a 
                href={linkIcon} 
                target="_blank" 
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className={style.icon}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                  />
                </svg>
              </motion.a>
            )}
            {twitterIcon && (
              <motion.a 
                href={twitterIcon} 
                target="_blank" 
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className={style.icon}
                >
                  <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
                </svg>
              </motion.a>
            )}
          </div>
        </div>
        <motion.div 
          className={`${style.statusBadge} ${style[status]}`}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.3 }}
        >
          {status}
        </motion.div>
      </section>
      <section className={style.content}>
        <div className={style.progress}>
          <p>Progress: {progress}%</p>
          <motion.div 
            className={style.progressBar}
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 1, delay: 0.5 }}
          />
        </div>
        <div className={style.supporters}>
          <p>Top Supporters:</p>
          <motion.div 
            className={style.supportersList}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.7 }}
          >
            {supporters.slice(0, 4).map((supporter, idx) => (
              <motion.div 
                key={idx} 
                className={style.supporter}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Image src={supporter.image} alt={supporter.name} />
                <span>{supporter.name}</span>
              </motion.div>
            ))}
          </motion.div>
        </div>
        <div className={style.details}>
          <p>Under Project: {underProject}</p>
        </div>
      </section>
      <section className={style.items}>
        <h3>Items Needed:</h3>
        <ul>
          {items.map((item, idx) => (
            <motion.li 
              key={idx}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 * idx }}
            >
              <div className={style.itemImage}>
                <Image src={item.image} alt={item.name} />
              </div>
              <div className={style.itemContent}>
                <h4>{item.name}</h4>
                <p>{item.description}</p>
                <p className={style.cost}>Cost: ${item.purchaseLink.toLocaleString()}</p>
                <p className={style.date}>Listed: {moment(item.dateListed).format('MMMM D, YYYY')}</p>
              </div>
            </motion.li>
          ))}
        </ul>
      </section>
    </motion.div>
  );
}

export default SupportItem;