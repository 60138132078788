import React, { useState, useEffect } from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import Project from "../TalkBus/Project";
import Button from "../Button/Button";
import ProjectSkeleton from "../ProjectSkeleton/ProjectSkeleton";
import { AxiosCont } from "../../constants/AxiosCont";

function TalkBusiness() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const config = async () => {
      const { data:results } = await AxiosCont.get("/talk-business")
        const result = results?.data;
        setProjects(
          await result.map((project) => ({
            ...project,
            technologies: project.demos,
          }))
        );
        console.log({ main_result: result });
        setLoading(false);
    }
    config();
  }, []);

  return (
    <section className="projects" id="projects">
      <SectionHeading>JUST BUSINESS</SectionHeading>
      <p className="talk">
        Here's what we offer, 
      </p>

      <section className="projects-container">
        {loading ? (
          <>
            <ProjectSkeleton />
            <ProjectSkeleton />
          </>
        ) : (
          projects.map((project, index) => (
            <Project project={project} key={project.name} index={index} />
          ))
        )}
      </section>

    </section>
  );
}

export default TalkBusiness;
