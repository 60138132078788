import React from "react";
import {
  FaGithub, FaLinkedin, FaTwitter, FaFacebook, FaStar, FaLightbulb,
  FaYoutube, FaMailBulk, FaMailchimp, FaMedal, FaAmbulance,
  FaCreativeCommonsSamplingPlus, FaEnvelope, FaDownload,
  FaCloudDownloadAlt, FaFileDownload, FaInstagram
} from "react-icons/fa";

const IconLinks = ({ idea, business, resume }) => {
  const iconSize = 20;

  const styles = {
    container: {
      display: "flex",
      gap: "20px",
      alignItems: "center",
      justifyContent: "center",
      padding: "9px",
    },
    smallScreenContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      gap: "10px",
      justifyContent: "center",
    },
    circle: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      backgroundColor: "#181820",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      textDecoration: "none",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      transition: "transform 0.3s, background-color 0.3s",
    },
    circleDisabled: {
      display: "none",
      backgroundColor: "#444",
      color: "#aaa",
      pointerEvents: "none",
      boxShadow: "none",
    },
  };

  const handleMouseOver = (e) => {
    e.currentTarget.style.transform = "scale(1.1)";
    e.currentTarget.style.backgroundColor = "#282838";
  };

  const handleMouseOut = (e) => {
    e.currentTarget.style.transform = "scale(1)";
    e.currentTarget.style.backgroundColor = "#181820";
  };

  const isSmallScreen = window.innerWidth <= 600;

  return (
    <div style={isSmallScreen ? styles.smallScreenContainer : styles.container}>
      <a
        href="https://github.com/Coderdivine"
        target="_blank"
        rel="noopener noreferrer"
        style={styles.circle}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <FaGithub size={iconSize} />
      </a>

      <a
        href="https://www.linkedin.com/in/chimdi-ezechukwu-48661a228/"
        target="_blank"
        rel="noopener noreferrer"
        style={styles.circle}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <FaLinkedin size={iconSize} />
      </a>

      <a
        href="https://www.youtube.com/@divthecreator6074"
        target="_blank"
        rel="noopener noreferrer"
        style={styles.circle}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <FaYoutube size={iconSize} />
      </a>

      <a
        href="/goal"
        style={idea ? styles.circle : styles.circleDisabled}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <FaStar size={iconSize} />
      </a>

      <a
        href="/talkbusiness"
        style={business ? styles.circle : styles.circleDisabled}
      >
        <FaLightbulb size={iconSize} />
      </a>

      <a href="mailto:chimdi4332@gmail.com" style={styles.circle}>
        <FaEnvelope size={iconSize} />
      </a>

      <a
        href="https://www.canva.com/design/DAGOlV-pRYg/Gu1vbmehip6mzcGqHc66EA/edit?utm_content=DAGOlV-pRYg&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
        target="_blank"
        rel="noopener noreferrer"
        style={resume ? styles.circle : styles.circleDisabled}
      >
        <FaFileDownload size={iconSize} />
      </a>

      <a
        href="https://x.com/ChimdiXo"
        target="_blank"
        rel="noopener noreferrer"
        style={styles.circle}
      >
        <FaTwitter size={iconSize} />
      </a>

      <a
        href="https://instagram.com/0xchimdi"
        target="_blank"
        rel="noopener noreferrer"
        style={resume ? styles.circle : styles.circleDisabled}
      >
        <FaInstagram size={iconSize} />
      </a>
    </div>
  );
};

export default IconLinks;
