import React from "react";

function HeroImage() {
  return (
    <svg
      width="463"
      height="301"
      viewBox="0 0 463 301"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="6" width="457" height="295" rx="5" fill="#FFFD77" />
      <path
        d="M451.205 293.548H18.3029C15.3478 293.544 12.5146 292.369 10.4251 290.28C8.33547 288.19 7.16008 285.357 7.15674 282.402V15.4654C7.15915 13.3005 8.02022 11.2249 9.55105 9.69399C11.0819 8.1631 13.1574 7.30193 15.3224 7.29944H454.044C456.247 7.30194 458.358 8.17797 459.916 9.73533C461.473 11.2927 462.349 13.4042 462.351 15.6066V282.402C462.348 285.357 461.173 288.19 459.083 290.28C456.994 292.369 454.16 293.544 451.205 293.548V293.548Z"
        fill="#F2F2F2"
      />
      <path
        d="M437.208 279.475H33.7545C27.8403 279.475 23.0288 274.969 23.0288 269.431V36.9798C23.0288 32.8726 26.5942 29.5309 30.9768 29.5309H439.854C444.309 29.5309 447.934 32.9276 447.934 37.1025V269.431C447.934 274.969 443.122 279.475 437.208 279.475Z"
        fill="white"
      />
      <path
        d="M462.196 16.8545H7V11.1798C7.00429 8.21485 8.18448 5.37267 10.2817 3.27678C12.3788 1.18089 15.2217 0.00245849 18.1867 0H451.009C453.974 0.00243348 456.817 1.18086 458.914 3.27675C461.011 5.37265 462.192 8.21484 462.196 11.1798V16.8545Z"
        fill="#3F3D56"
      />
      <path
        d="M27.3301 11.3377C28.9281 11.3377 30.2236 10.0422 30.2236 8.44409C30.2236 6.84603 28.9281 5.55054 27.3301 5.55054C25.732 5.55054 24.4365 6.84603 24.4365 8.44409C24.4365 10.0422 25.732 11.3377 27.3301 11.3377Z"
        fill="white"
      />
      <path
        d="M38.3135 11.3377C39.9115 11.3377 41.207 10.0422 41.207 8.44409C41.207 6.84603 39.9115 5.55054 38.3135 5.55054C36.7154 5.55054 35.4199 6.84603 35.4199 8.44409C35.4199 10.0422 36.7154 11.3377 38.3135 11.3377Z"
        fill="white"
      />
      <path
        d="M49.2969 11.3377C50.8949 11.3377 52.1904 10.0422 52.1904 8.44409C52.1904 6.84603 50.8949 5.55054 49.2969 5.55054C47.6988 5.55054 46.4033 6.84603 46.4033 8.44409C46.4033 10.0422 47.6988 11.3377 49.2969 11.3377Z"
        fill="white"
      />
      <path
        d="M225.91 80.3568H147.003C145.827 80.3568 144.699 79.8895 143.867 79.0576C143.035 78.2258 142.568 77.0976 142.568 75.9212C142.568 74.7448 143.035 73.6166 143.867 72.7848C144.699 71.9529 145.827 71.4856 147.003 71.4856H225.91C227.087 71.4856 228.215 71.9529 229.047 72.7848C229.879 73.6166 230.346 74.7448 230.346 75.9212C230.346 77.0976 229.879 78.2258 229.047 79.0576C228.215 79.8895 227.087 80.3568 225.91 80.3568V80.3568Z"
        fill="#CCCCCC"
      />
      <path
        d="M323.96 80.3568H245.053C243.877 80.3568 242.749 79.8895 241.917 79.0576C241.085 78.2258 240.618 77.0976 240.618 75.9212C240.618 74.7448 241.085 73.6166 241.917 72.7848C242.749 71.9529 243.877 71.4856 245.053 71.4856H323.96C325.136 71.4856 326.265 71.9529 327.096 72.7848C327.928 73.6166 328.396 74.7448 328.396 75.9212C328.396 77.0976 327.928 78.2258 327.096 79.0576C326.265 79.8895 325.136 80.3568 323.96 80.3568V80.3568Z"
        fill="#CCCCCC"
      />
      <path
        d="M323.96 97.1652H186.69C185.514 97.1652 184.385 96.6979 183.554 95.8661C182.722 95.0342 182.254 93.906 182.254 92.7297C182.254 91.5533 182.722 90.425 183.554 89.5932C184.385 88.7614 185.514 88.2941 186.69 88.2941H323.96C325.136 88.2941 326.264 88.7614 327.096 89.5932C327.928 90.425 328.395 91.5533 328.395 92.7297C328.395 93.906 327.928 95.0342 327.096 95.8661C326.264 96.6979 325.136 97.1652 323.96 97.1652Z"
        fill="#CCCCCC"
      />
      <path
        d="M225.91 63.3147H147.003C145.828 63.3132 144.701 62.8452 143.87 62.0136C143.04 61.1819 142.573 60.0545 142.573 58.8791C142.573 57.7036 143.04 56.5762 143.87 55.7446C144.701 54.9129 145.828 54.4449 147.003 54.4435H225.91C226.493 54.4428 227.07 54.557 227.609 54.7795C228.148 55.0021 228.638 55.3287 229.05 55.7407C229.463 56.1527 229.79 56.6419 230.013 57.1804C230.236 57.7189 230.351 58.2961 230.351 58.8791C230.351 59.462 230.236 60.0392 230.013 60.5778C229.79 61.1163 229.463 61.6055 229.05 62.0174C228.638 62.4294 228.148 62.756 227.609 62.9786C227.07 63.2012 226.493 63.3154 225.91 63.3147V63.3147Z"
        fill="#CCCCCC"
      />
      <path
        d="M269.332 63.5482H246.92C245.744 63.5482 244.616 63.0809 243.784 62.249C242.952 61.4172 242.485 60.289 242.485 59.1126C242.485 57.9362 242.952 56.808 243.784 55.9762C244.616 55.1443 245.744 54.677 246.92 54.677H269.332C270.508 54.677 271.636 55.1443 272.468 55.9762C273.3 56.808 273.767 57.9362 273.767 59.1126C273.767 60.289 273.3 61.4172 272.468 62.249C271.636 63.0809 270.508 63.5482 269.332 63.5482Z"
        fill="#CCCCCC"
      />
      <path
        d="M169.415 97.1652H147.003C145.828 97.1638 144.701 96.6958 143.87 95.8642C143.04 95.0325 142.573 93.9051 142.573 92.7297C142.573 91.5542 143.04 90.4268 143.87 89.5951C144.701 88.7635 145.828 88.2955 147.003 88.2941H169.415C169.998 88.2934 170.575 88.4076 171.114 88.6301C171.653 88.8527 172.142 89.1793 172.555 89.5913C172.967 90.0032 173.294 90.4925 173.518 91.031C173.741 91.5695 173.856 92.1467 173.856 92.7297C173.856 93.3126 173.741 93.8898 173.518 94.4283C173.294 94.9668 172.967 95.4561 172.555 95.868C172.142 96.28 171.653 96.6066 171.114 96.8292C170.575 97.0518 169.998 97.166 169.415 97.1652Z"
        fill="#CCCCCC"
      />
      <path
        d="M225.91 237.754H147.003C145.827 237.754 144.699 237.287 143.867 236.455C143.035 235.623 142.568 234.495 142.568 233.318C142.568 232.142 143.035 231.014 143.867 230.182C144.699 229.35 145.827 228.883 147.003 228.883H225.91C226.493 228.883 227.07 228.998 227.608 229.22C228.146 229.443 228.635 229.77 229.047 230.182C229.459 230.594 229.785 231.083 230.008 231.621C230.231 232.159 230.346 232.736 230.346 233.318C230.346 233.901 230.231 234.478 230.008 235.016C229.785 235.554 229.459 236.043 229.047 236.455C228.635 236.867 228.146 237.193 227.608 237.416C227.07 237.639 226.493 237.754 225.91 237.754V237.754Z"
        fill="#CCCCCC"
      />
      <path
        d="M323.96 237.754H245.053C243.877 237.754 242.749 237.287 241.917 236.455C241.085 235.623 240.618 234.495 240.618 233.318C240.618 232.142 241.085 231.014 241.917 230.182C242.749 229.35 243.877 228.883 245.053 228.883H323.96C324.543 228.883 325.119 228.998 325.657 229.22C326.196 229.443 326.685 229.77 327.096 230.182C327.508 230.594 327.835 231.083 328.058 231.621C328.281 232.159 328.396 232.736 328.396 233.318C328.396 233.901 328.281 234.478 328.058 235.016C327.835 235.554 327.508 236.043 327.096 236.455C326.685 236.867 326.196 237.193 325.657 237.416C325.119 237.639 324.543 237.754 323.96 237.754V237.754Z"
        fill="#CCCCCC"
      />
      <path
        d="M323.96 254.563H186.69C185.514 254.563 184.385 254.095 183.554 253.263C182.722 252.432 182.254 251.303 182.254 250.127C182.254 248.951 182.722 247.822 183.554 246.99C184.385 246.159 185.514 245.691 186.69 245.691H323.96C324.543 245.691 325.12 245.805 325.659 246.027C326.198 246.25 326.687 246.577 327.1 246.989C327.512 247.401 327.839 247.89 328.063 248.428C328.286 248.967 328.401 249.544 328.401 250.127C328.401 250.71 328.286 251.287 328.063 251.826C327.839 252.364 327.512 252.853 327.1 253.265C326.687 253.677 326.198 254.004 325.659 254.226C325.12 254.449 324.543 254.563 323.96 254.563Z"
        fill="#CCCCCC"
      />
      <path
        d="M225.91 220.712H147.003C145.827 220.712 144.699 220.245 143.867 219.413C143.035 218.581 142.568 217.453 142.568 216.276C142.568 215.1 143.035 213.972 143.867 213.14C144.699 212.308 145.827 211.841 147.003 211.841H225.91C227.087 211.841 228.215 212.308 229.047 213.14C229.879 213.972 230.346 215.1 230.346 216.276C230.346 217.453 229.879 218.581 229.047 219.413C228.215 220.245 227.087 220.712 225.91 220.712Z"
        fill="#CCCCCC"
      />
      <path
        d="M269.332 220.945H246.92C245.744 220.945 244.616 220.478 243.784 219.646C242.952 218.814 242.485 217.686 242.485 216.51C242.485 215.333 242.952 214.205 243.784 213.373C244.616 212.542 245.744 212.074 246.92 212.074H269.332C270.508 212.074 271.636 212.542 272.468 213.373C273.3 214.205 273.767 215.333 273.767 216.51C273.767 217.686 273.3 218.814 272.468 219.646C271.636 220.478 270.508 220.945 269.332 220.945V220.945Z"
        fill="#CCCCCC"
      />
      <path
        d="M169.415 254.563H147.003C145.827 254.563 144.699 254.095 143.867 253.263C143.035 252.432 142.568 251.303 142.568 250.127C142.568 248.951 143.035 247.822 143.867 246.99C144.699 246.159 145.827 245.691 147.003 245.691H169.415C169.998 245.691 170.575 245.805 171.114 246.027C171.653 246.25 172.142 246.577 172.555 246.989C172.967 247.401 173.294 247.89 173.518 248.428C173.741 248.967 173.856 249.544 173.856 250.127C173.856 250.71 173.741 251.287 173.518 251.826C173.294 252.364 172.967 252.853 172.555 253.265C172.142 253.677 171.653 254.004 171.114 254.226C170.575 254.449 169.998 254.563 169.415 254.563Z"
        fill="#CCCCCC"
      />
      <path
        d="M205.302 190.134C203.628 190.138 202.019 189.48 200.828 188.304L171.166 159.013C170.584 158.438 170.122 157.753 169.806 156.998C169.491 156.243 169.328 155.433 169.328 154.615C169.328 153.796 169.491 152.986 169.806 152.231C170.122 151.476 170.584 150.791 171.166 150.216L200.708 121.044C201.939 119.834 203.597 119.159 205.322 119.164C207.048 119.17 208.701 119.856 209.924 121.073C211.149 122.3 211.836 123.962 211.836 125.696C211.836 127.429 211.149 129.091 209.925 130.318L187.973 152.271C187.289 152.956 186.905 153.885 186.905 154.853C186.905 155.822 187.289 156.751 187.973 157.436L209.805 179.269C210.697 180.159 211.304 181.293 211.551 182.529C211.797 183.764 211.671 185.045 211.189 186.208C210.706 187.372 209.889 188.366 208.841 189.065C207.794 189.764 206.562 190.136 205.302 190.134V190.134Z"
        fill="#6C63FF"
      />
      <path
        d="M252.565 190.134C251.306 190.136 250.074 189.764 249.026 189.065C247.978 188.366 247.161 187.372 246.679 186.208C246.196 185.045 246.07 183.764 246.317 182.529C246.563 181.293 247.17 180.159 248.062 179.269L269.895 157.436C270.578 156.751 270.963 155.822 270.963 154.853C270.963 153.885 270.578 152.956 269.895 152.271L247.943 130.318C247.336 129.711 246.854 128.991 246.525 128.197C246.197 127.404 246.028 126.554 246.028 125.695C246.028 124.837 246.197 123.987 246.526 123.194C246.854 122.4 247.336 121.68 247.943 121.073V121.073C249.166 119.856 250.82 119.17 252.545 119.164C254.271 119.159 255.929 119.834 257.159 121.044L286.701 150.216C287.283 150.791 287.746 151.476 288.061 152.231C288.377 152.986 288.539 153.796 288.539 154.615C288.539 155.433 288.377 156.243 288.061 156.998C287.746 157.753 287.283 158.438 286.701 159.013L257.039 188.304C255.848 189.48 254.24 190.138 252.565 190.134Z"
        fill="#6C63FF"
      />
    </svg>
  );
}

export default HeroImage;
