import React from "react";

function Footer() {
  /* <p className="credits">Design by Trust</p> */
  return (
    <footer className="footer">
      <p className="caption">
        LET'S BUILD THE<span> FUTURE</span> TOGETHER
      </p>
      <hr />
      <p className="credits">0b00111100</p>
      <br/>
      <p className="credits">Design inspiration by <a  href="https://www.twitter.com/yourboy_tee">Trust</a></p> 
      <div className="icons">
        <a href="https://github.com/Coderdivine" target="_blank">
          <svg
            width="28"
            height="29"
            viewBox="0 0 28 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_178_58)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7115 0.788574C6.13591 0.788574 0 6.92449 0 14.5001C0 20.5675 3.92493 25.6922 9.37526 27.5089C10.0608 27.6289 10.3179 27.2176 10.3179 26.8576C10.3179 26.532 10.3008 25.4522 10.3008 24.3039C6.85577 24.938 5.96452 23.464 5.69029 22.6928C5.53603 22.2985 4.8676 21.0817 4.28486 20.756C3.80495 20.4989 3.11937 19.8648 4.26772 19.8476C5.3475 19.8305 6.11877 20.8417 6.37587 21.253C7.6099 23.3269 9.58094 22.7442 10.3694 22.3842C10.4893 21.493 10.8493 20.8931 11.2435 20.5503C8.19264 20.2075 5.00471 19.0249 5.00471 13.7803C5.00471 12.2891 5.53603 11.0551 6.41014 10.0953C6.27303 9.75249 5.79313 8.34706 6.54726 6.46172C6.54726 6.46172 7.6956 6.1018 10.3179 7.86716C11.4149 7.55865 12.5803 7.40439 13.7458 7.40439C14.9113 7.40439 16.0768 7.55865 17.1737 7.86716C19.796 6.08466 20.9444 6.46172 20.9444 6.46172C21.6985 8.34706 21.2186 9.75249 21.0815 10.0953C21.9556 11.0551 22.4869 12.272 22.4869 13.7803C22.4869 19.0421 19.2819 20.2075 16.231 20.5503C16.7281 20.9788 17.1566 21.8015 17.1566 23.087C17.1566 24.9209 17.1394 26.3949 17.1394 26.8576C17.1394 27.2176 17.3965 27.646 18.0821 27.5089C20.804 26.5899 23.1693 24.8405 24.8449 22.5069C26.5206 20.1733 27.4223 17.373 27.4231 14.5001C27.4231 6.92449 21.2872 0.788574 13.7115 0.788574Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_178_58">
                <rect
                  width="27.4231"
                  height="27.4231"
                  fill="white"
                  transform="translate(0 0.788574)"
                />
              </clipPath>
            </defs>
          </svg>
        </a>
        <a href="mailto:chimdi4332@gmail.com" target="_blank">
          <svg
            width="32"
            height="31"
            viewBox="0 0 32 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.6626 9.11991L16.0579 15.3168L28.4533 9.11991C28.4074 8.33009 28.0613 7.58771 27.4858 7.04481C26.9104 6.5019 26.1491 6.19956 25.3579 6.19971H6.75795C5.9668 6.19956 5.20554 6.5019 4.63007 7.04481C4.05461 7.58771 3.70848 8.33009 3.6626 9.11991Z"
              fill="white"
            />
            <path
              d="M28.4577 12.583L16.0577 18.783L3.65771 12.583V21.7001C3.65771 22.5223 3.98432 23.3108 4.56568 23.8921C5.14705 24.4735 5.93554 24.8001 6.75771 24.8001H25.3577C26.1799 24.8001 26.9684 24.4735 27.5497 23.8921C28.1311 23.3108 28.4577 22.5223 28.4577 21.7001V12.583Z"
              fill="white"
            />
          </svg>
        </a>
        <a href="https://twitter.com/ChimdiXo" target="_blank">
          <svg
            width="32"
            height="31"
            viewBox="0 0 32 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_178_63)">
              <path
                d="M31.2314 6.37696C30.1529 6.85487 28.9942 7.17779 27.7762 7.32375C29.033 6.57173 29.9733 5.38814 30.4215 3.99383C29.2408 4.69519 27.9484 5.18887 26.6008 5.45341C25.6945 4.48578 24.4942 3.84442 23.186 3.6289C21.8779 3.41338 20.5352 3.63577 19.3665 4.26153C18.1977 4.8873 17.2682 5.88142 16.7223 7.08957C16.1764 8.29772 16.0447 9.6523 16.3475 10.943C13.955 10.8229 11.6144 10.201 9.47771 9.11775C7.34103 8.0345 5.456 6.51408 3.94496 4.65516C3.42829 5.54641 3.13121 6.57975 3.13121 7.68025C3.13063 8.67095 3.3746 9.64648 3.84147 10.5203C4.30833 11.3941 4.98367 12.1391 5.80754 12.6893C4.85206 12.6589 3.91767 12.4008 3.08212 11.9363V12.0138C3.08203 13.4033 3.56267 14.75 4.44248 15.8255C5.3223 16.901 6.54711 17.6389 7.90908 17.9141C7.02272 18.154 6.09343 18.1893 5.19142 18.0175C5.57568 19.213 6.3242 20.2585 7.33218 21.0076C8.34016 21.7566 9.55714 22.1717 10.8127 22.1947C8.68128 23.8679 6.04894 24.7756 3.33917 24.7716C2.85916 24.7717 2.37956 24.7437 1.90283 24.6876C4.65341 26.4561 7.85527 27.3947 11.1253 27.3911C22.1949 27.3911 28.2464 18.2228 28.2464 10.2713C28.2464 10.013 28.2399 9.75208 28.2283 9.49375C29.4054 8.64251 30.4214 7.5884 31.2288 6.38083L31.2314 6.37696Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_178_63">
                <rect
                  width="31"
                  height="31"
                  fill="white"
                  transform="translate(0.692383)"
                />
              </clipPath>
            </defs>
          </svg>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
