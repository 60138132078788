import React, { useState, useEffect } from "react";
import { AxiosCont } from "../../constants/AxiosCont";
import Alert from "../Alert/Alert";
import Form from "../Form/Form";
import Info from "../Info/Info";
import SectionHeading from "../SectionHeading/SectionHeading";

function Contact() {
  const [isAvailable, setIsAvailable] = useState(false);
  const [alertActive, setAlertActive] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    AxiosCont.get("/check").then((res) => {
       const response = res.data.data;
      setIsAvailable(!!response[0]?.is_available);
    });
  }, []);

  return (
    <section className="contact" id="contact">
      <Alert
        message={message}
        active={alertActive}
        setActive={setAlertActive}
      />
      <div className="inner">
        <SectionHeading>CONTΔCT MΣ</SectionHeading>
        <div className="grid">
          <section className="info">
            <p>
              {isAvailable
                ? "I'm currently open to collaborations and projects. My inbox is open, so invite me lets build the impossible 😁."
                : "I'm currently open to brainstrom on your project"}
            </p>
            <div className="info-items">
              <Info
                icon={
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.00464 8.826L15.0001 14.823L26.9956 8.826C26.9512 8.06167 26.6163 7.34323 26.0594 6.81784C25.5025 6.29245 24.7658 5.99986 24.0001 6H6.00014C5.23452 5.99986 4.49781 6.29245 3.94091 6.81784C3.384 7.34323 3.04904 8.06167 3.00464 8.826Z"
                      fill="white"
                    />
                    <path
                      d="M27 12.1772L15 18.1772L3 12.1772V21.0002C3 21.7959 3.31607 22.559 3.87868 23.1216C4.44129 23.6842 5.20435 24.0002 6 24.0002H24C24.7956 24.0002 25.5587 23.6842 26.1213 23.1216C26.6839 22.559 27 21.7959 27 21.0002V12.1772Z"
                      fill="white"
                    />
                  </svg>
                }
                item="Email"
                setMessage={setMessage}
                setAlertActive={setAlertActive}
              >
                chimdi4332@gmail.com
              </Info>

              <Info
                icon={
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 4.5C3 4.10218 3.15804 3.72064 3.43934 3.43934C3.72064 3.15804 4.10218 3 4.5 3H7.7295C8.08456 3.00016 8.42805 3.12627 8.69887 3.35589C8.96968 3.58551 9.15027 3.90375 9.2085 4.254L10.3185 10.9065C10.3715 11.2233 10.3215 11.5488 10.1756 11.835C10.0298 12.1213 9.79597 12.3531 9.5085 12.4965L7.1865 13.656C8.01916 15.7195 9.25925 17.5939 10.8327 19.1673C12.4061 20.7408 14.2805 21.9808 16.344 22.8135L17.505 20.4915C17.6483 20.2043 17.8799 19.9706 18.1658 19.8248C18.4518 19.679 18.7769 19.6288 19.0935 19.6815L25.746 20.7915C26.0963 20.8497 26.4145 21.0303 26.6441 21.3011C26.8737 21.572 26.9998 21.9154 27 22.2705V25.5C27 25.8978 26.842 26.2794 26.5607 26.5607C26.2794 26.842 25.8978 27 25.5 27H22.5C11.73 27 3 18.27 3 7.5V4.5Z"
                      fill="white"
                    />
                  </svg>
                }
                item="Number"
                setMessage={setMessage}
                setAlertActive={setAlertActive}
              >
                +234-810740406
              </Info>
            </div>
          </section>

          <Form setAlertMessage={setMessage} setAlertActive={setAlertActive} />
        </div>
      </div>
      <svg
        className="position"
        width="315"
        height="346"
        viewBox="0 0 315 346"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.9165 13.4771V215.141M22.9165 215.141H314.718M22.9165 215.141L155.831 345"
          stroke="#51516C"
          strokeWidth="1.52776"
        />
        <path
          d="M192.492 73.1828C192.561 72.3418 191.934 71.6048 191.093 71.5366L177.388 70.4254C176.547 70.3572 175.81 70.9837 175.742 71.8247C175.674 72.6657 176.3 73.4027 177.141 73.4709L189.323 74.4586L188.336 86.6407C188.268 87.4817 188.894 88.2188 189.735 88.287C190.576 88.3551 191.313 87.7287 191.381 86.8877L192.492 73.1828ZM23.906 217.069L191.959 74.2234L189.98 71.8953L21.9271 214.74L23.906 217.069Z"
          fill="#FFFD75"
          fillOpacity="0.51"
        />
        <path
          d="M9.30136 246.094C7.71046 246.094 6.30938 245.76 5.09812 245.091C3.88687 244.422 2.94679 243.482 2.27788 242.271C1.60898 241.06 1.27453 239.686 1.27453 238.149C1.27453 235.998 1.75361 234.063 2.71177 232.346C3.688 230.61 5.02581 229.254 6.72518 228.278C8.44264 227.302 10.359 226.814 12.4741 226.814C14.0831 226.814 15.4932 227.148 16.7045 227.817C17.9157 228.486 18.8558 229.426 19.5247 230.637C20.1936 231.83 20.5281 233.195 20.5281 234.732C20.5281 236.883 20.04 238.827 19.0637 240.562C18.1056 242.298 16.7678 243.654 15.0503 244.63C13.3509 245.606 11.4346 246.094 9.30136 246.094ZM9.73524 243.301C11.2177 243.301 12.5284 242.922 13.6673 242.162C14.8243 241.403 15.7192 240.391 16.352 239.125C17.0028 237.842 17.3282 236.45 17.3282 234.949C17.3282 233.304 16.8582 232.011 15.9181 231.071C14.9961 230.113 13.7035 229.634 12.0402 229.634C10.5578 229.634 9.23809 230.014 8.08107 230.773C6.94212 231.514 6.05628 232.517 5.42353 233.783C4.79079 235.048 4.47442 236.44 4.47442 237.959C4.47442 239.604 4.93542 240.906 5.85742 241.864C6.7975 242.822 8.09011 243.301 9.73524 243.301Z"
          fill="#51516C"
        />
        <path
          d="M218.432 68.8837H210.54L208.506 72.7344H205.279L215.367 53.8605H218.947L222.364 72.7344H219.109L218.432 68.8837ZM218.025 66.4702L216.479 57.6027L211.815 66.4702H218.025Z"
          fill="#51516C"
        />
        <path
          d="M100.123 33.1898C98.5322 33.1898 97.1312 32.8554 95.9199 32.1865C94.7086 31.5176 93.7686 30.5775 93.0997 29.3662C92.4308 28.155 92.0963 26.781 92.0963 25.2443C92.0963 23.093 92.5754 21.1586 93.5335 19.4412C94.5098 17.7056 95.8476 16.3497 97.547 15.3735C99.2644 14.3973 101.181 13.9092 103.296 13.9092C104.905 13.9092 106.315 14.2436 107.526 14.9125C108.738 15.5814 109.678 16.5215 110.347 17.7328C111.015 18.9259 111.35 20.2909 111.35 21.8275C111.35 23.9789 110.862 25.9223 109.885 27.6578C108.927 29.3933 107.59 30.7492 105.872 31.7255C104.173 32.7017 102.256 33.1898 100.123 33.1898ZM100.557 30.3967C102.039 30.3967 103.35 30.0171 104.489 29.2578C105.646 28.4985 106.541 27.4861 107.174 26.2206C107.825 24.937 108.15 23.545 108.15 22.0445C108.15 20.3993 107.68 19.1067 106.74 18.1666C105.818 17.2085 104.525 16.7294 102.862 16.7294C101.38 16.7294 100.06 17.109 98.9028 17.8683C97.7639 18.6096 96.8781 19.6129 96.2453 20.8784C95.6126 22.1439 95.2962 23.5359 95.2962 25.0545C95.2962 26.6997 95.7572 28.0013 96.6792 28.9595C97.6193 29.9176 98.9119 30.3967 100.557 30.3967ZM124.751 29.1493H116.86L114.826 33H111.599L121.687 14.1261H125.266L128.683 33H125.429L124.751 29.1493ZM124.344 26.7358L122.798 17.8683L118.134 26.7358H124.344Z"
          fill="#51516C"
        />
        <path
          d="M132.476 4.93294C132.7 4.7092 132.7 4.34646 132.476 4.12272L128.83 0.476757C128.606 0.253022 128.244 0.253022 128.02 0.476757C127.796 0.700492 127.796 1.06324 128.02 1.28697L131.261 4.52783L128.02 7.76869C127.796 7.99243 127.796 8.35517 128.02 8.57891C128.244 8.80264 128.606 8.80264 128.83 8.57891L132.476 4.93294ZM93.877 5.10074H132.071V3.95492H93.877V5.10074Z"
          fill="#51516C"
        />
      </svg>
    </section>
  );
}

export default Contact;
