import React, { useState, useEffect } from "react";
import { api } from "../../constants";
import { AxiosCont } from "../../constants/AxiosCont";
import ParagraphSkeleton from "../ParagraphSkeleton/ParagraphSkeleton";

function Currently(props) {
  const [currently, setCurrently] = useState(<p>I Spend 49.23% of my free time building <a href="https://axgura.com"  style={{margin:"2px",padding:"2px",color:"red",fontWeight:"bolder"}}>AXGURA</a></p>);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AxiosCont.get("/currently").then((result) => {
      const st_ = result.data.currently;
      if(st_){
        setCurrently(<p>I Spend 49.23% of my free time building <a href="https://axgura.com"  style={{margin:"2px",padding:"2px",color:"red",fontWeight:"bolder"}}>AXGURA</a></p>);
        setLoading(false);
      }else{
        setCurrently(<p>I'm building axgura</p>);
        setLoading(false);
      }
    });
  }, []);

  return (
    <section {...props}>
      {loading ? (
        <>
          <ParagraphSkeleton />
          <ParagraphSkeleton />
          <ParagraphSkeleton />
        </>
      ) : (
        <p>{currently}</p>
      )}
    </section>
  );
}

export default Currently;
