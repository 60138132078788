import React, { useState, useEffect } from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import whotImage from "../../assets/whot.png";
import cryptoImage from "../../assets/crypto.png";
import Project from "../Project/Project";
import Button from "../Button/Button";
import Currently from "../Currently/Currently";
import ProjectSkeleton from "../ProjectSkeleton/ProjectSkeleton";
import { AxiosCont } from "../../constants/AxiosCont";
import SupportItem from "../Support/Support";

function SupportSystem() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const data = [
      {
        "name": "Warehouse Funding",
        "image": "https://iili.io/dW3q4bs.png",
        "progress": 65,
        "supporters": [
          {
            "name": "John Doe",
            "image": "https://iili.io/dW3q4bs.png"
          },
          {
            "name": "Jane Smith",
            "image": "https://iili.io/dW3q4bs.png"
          },
          {
            "name": "Bob Johnson",
            "image": "https://iili.io/dW3q4bs.png"
          },
          {
            "name": "Alice Brown",
            "image": "https://iili.io/dW3q4bs.png"
          }
        ],
        "linkIcon": "https://example.com/warehouse-project",
        "twitterIcon": "https://twitter.com/warehouseproject",
        "status": "opened",
        "underProject": "Axgura",
        "items": [
          {
            "name": "Annual Warehouse Rent",
            "image": "https://iili.io/dW3q4bs.png",
            "description": "Funding for yearly warehouse rental",
            "purchaseLink": 300,
            "dateListed": "2024-03-15"
          },
          {
            "name": "3D Printer",
            "image": "https://iili.io/dW3q4bs.png",
            "description": "High-quality 3D printer for prototyping",
            "purchaseLink": 1200,
            "dateListed": "2024-03-16"
          },
          {
            "name": "Workstation Computer",
            "image": "https://iili.io/dW3q4bs.png",
            "description": "Powerful computer for 3D modeling and design",
            "purchaseLink": 2000,
            "dateListed": "2024-03-17"
          },
          {
            "name": "Electrical Safety Equipment",
            "image": "https://iili.io/dW3q4bs.png",
            "description": "Essential safety gear for electrical work",
            "purchaseLink": 500,
            "dateListed": "2024-03-18"
          }
        ]
      }
    ]

  // useEffect(() => {
  //   const fetchProjects = async () => {
  //     try {
  //       const results = await AxiosCont.get("/get-project");
  //       const result = results.data.data;
  //       console.log({ Projects: result });
  
  //       const parsedProjects = result.map((project) => {
  //         const parseField = (field) => {
  //           if (typeof field === "string") {
  //             try {
  //               return JSON.parse(field);
  //             } catch {
  //               return field.includes(",") ? field.split(",") : [field];
  //             }
  //           } else if (Array.isArray(field)) {
  //             return field;
  //           }
  //           return [];
  //         };
  
  //         return {
  //           ...project,
  //           roles: parseField(project.roles),
  //           technologies: parseField(project.technologies_json),
  //         };
  //       });
  
  //       setProjects(parsedProjects);
  //     } catch (error) {
  //       console.error("Error fetching projects:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  
  //   fetchProjects();
  // }, []);
  
  

  return (
    <section className="projects" id="projects">
      <SectionHeading>PRΘJECTS</SectionHeading>
      <p className="talk">
      In addition to producing stunning crafts, I have also been working on a range of 
      innovative projects that enhance daily life for humans and contribute to the greater good of society. My recent work
      
      </p>
      <section className="projects-container">
        {!loading ? (
          <>
            <ProjectSkeleton />
            <ProjectSkeleton />
          </>
        ) : (
          data.map((support, index) => (
            <SupportItem item={support} key={support.title} index={index} />
          ))
        )}
      </section>
      <Button className="btn" to={"/archive"} isLink>
        SEE MORE
      </Button>
      <Currently className="currently" />
    </section>
  );
}

export default SupportSystem;
